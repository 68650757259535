import React from "react";
import {
  PageLayout,
  About,
  DaySmartAppointmentBooking,
} from "@bluefin/components";
import { Grid, Divider } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class UnionCityPage extends React.PureComponent {
  render() {
    return (
      <WebsiteLayout>
        <PageLayout>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <About
                content={
                  "Clients can book an appointment online quickly and easily! With just a few steps, clients can pick the date and time they'd like to come in, and schedule multiple services with the first available employee or a selected employee of their choice."
                }
                header={"Book an Appointment Online"}
                centerContent={true}
                headerAs={"h1"}
                withContainer={true}
              />
              <Divider hidden={true} />
              <DaySmartAppointmentBooking
                guid={"a9b9f426-d024-494e-8053-559a30bd035c"}
                title={"Book Appointment Now"}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}
